@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

body, span, div, table, p, td, th, button {
  font-family: Raleway;
}

.remNavbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #1B3282 0% 0% no-repeat padding-box;
  z-index: 9999;

}

.passiveNav {
  width: 0px;
  margin: auto;
  height: 2px;
  background-color: #E2BD69;
  transition: width 0.3s ease-in-out;
  transition-delay: 0.15s;
}

.activeNav {
  width: 30px;
  transition: width 0.3s ease-in-out;
  transition-delay: 0.15s;
}

.headerLinks {
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}

.headerLinks:hover {
  color: #fff;
}

.heroSection {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('assets/images/Bg-01.webp') no-repeat;
  background-size: cover;
  opacity: 0.9;
  background-position-x: right;
}

.whiteSpan {
  color: #fff;
}

.visionTitle {
  font-weight: 600;
  font-size: 62px;
  letter-spacing: 0px;
}

.teamBackground {
  z-index: 1000;
}

.sectionBreak {
  padding-bottom: 7rem;
}

.teamTitle {
  font-size: 50px;
  font-weight: 600;
  color: #203787;
}

.teamBlueDiv {
  background: #203787;
  color: #fff;
}

.yellowLine {
  color: #E2BD69;
  height: 2px;
  opacity: 1;
}

.roleSpan {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 3.63px;
}

.roleName {
  font-weight: 600;
  letter-spacing: 0px;
}

.teamBackground {
  background: url('assets/images/dots.svg') no-repeat;
  background-position-x: center;
  background-position-y: -300px;
  background-size: cover;
  padding-top: 7rem;
}

.blueBgSection {
  background-color: #203787;
  color: #fff;
}

.videoDiv {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-size: cover;
  background-position-x: center;
}

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

#video1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

#video2 {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.video2Container {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-items: center;
}

.zieleDiv {
  position: absolute;
  z-index: 0;
}

.zieleDiv svg {
  width: 100%;
  max-width: 450px;
}

.leftBlur {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  height: 61px;
}

.rightBlur {
  left: unset;
  right: 0;
}

.orangeTitle {
  font-size: 40px;
  z-index: 2;
  position: relative;
  letter-spacing: 0px;
  color: #E2BD69;
  font-weight: 600;
}

.nGMgTop {
  margin-top: -88px;
  z-index: 1;
}

.dotsSection {
  background: url('assets/images/dots.svg') no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: -170px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  margin: auto;
}

.orangeClip {
  background-color: #E2BD69;
  width: 14px;
  height: 30px;
}

.footerBlueBg {
  background: url('assets/images/footerBg.webp') no-repeat;
  background-size: cover;
  opacity: 0.9;
  background-position-x: right;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerDots {
  background: url('assets/images/shape-01.webp') no-repeat;
  height: 400px;
  background-size: contain;
  background-position-y: center;
  display: flex;
  align-items: center;
}

.borderRight {
  border-right: 3px solid #fff;
}

.cookie-dialog {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  min-height: 100px;
  background-color: #203787;
}

.cookie-dialog .text-container--wide, .cookie-dialog .banner {
  padding-top: 40px;
  padding-bottom: 50px;
}

.cookie-dialog * {
  color: white;
}

.cookie-dialog p {
  max-width: 100%;
  margin-bottom: 30px;
}

.cookie_btn {
  background-color: #0E43E1 !important;
}

.cookie-dialog .button {
  border: none;
  font-size: 16px;
  color: #FFF;
  padding: 8px 16px;
  background-color: #4070F4;
  margin: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ds_cookie {
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  border: none;
  letter-spacing: 1.2px;
  text-decoration: underline;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 0px;
}

.button, .quandoo-widget-builder span.quandoo-button {
  display: inline-block;
  position: relative;
  font-size: 20px;
  padding: 10px 10px;
  z-index: 10;
  overflow: hidden;
  text-align: center;
  transition: transform 0.5s 0.1s, border-radius 0.3s;
  cursor: pointer;
  margin: 0 !important;
}

p+.button, .quandoo-widget-builder p+span.quandoo-button {
  float: right;
  text-align: right;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.Teilnahmebedingungen {
  padding-top: 7rem;
  padding-bottom: 5%;
  overflow-x: hidden;
}

.Teilnahmebedingungen h1 {
  color: #203787;
  font-size: 60px;
}

.Teilnahmebedingungen p {
  font-size: 25px;
  display: block;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.Teilnahmebedingungen h2 {
  font-size: 40px;
  color: #203787;
  padding-top: 3%;
}
.Teilnahmebedingungen h3 {
  color: #203787;
}
@media (min-width: 770px) and (max-width: 1280px) {
  .Teilnahmebedingungen p {
    font-size: 21px;
    display: block;
  }

  .Teilnahmebedingungen h1 {
    color: #203787;
    font-size: 45px;
  }

  .Teilnahmebedingungen h2 {
    font-size: 30px;
    color: #203787;
    padding-top: 3%;
  }

  .Teilnahmebedingungen h2 {
    font-size: 30px;
    color: #203787;
    padding-top: 3%;
  }
}

@media (max-width: 770px) {
  .Teilnahmebedingungen h1 {
    color: #203787;
    font-size: 30px;
  }
}

@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {
  .visionTitle {
    font-size: 50px;
  }

  .videoCol {
    height: 500px;
  }

  .videoDiv {
    height: 500px;
  }
}

@media (max-width: 991.98px) {
  .visionTitle {
    font-size: 40px;
  }

  .teamBackground {
    background-position-y: -200px;
  }


  .orangeTitle {
    font-size: 35px;
  }

  .leftBlur {
    height: 55px;
  }
}

@media (max-width: 767.98px) {
  .heroSection {
    height: auto;
    padding-top: 60px;
    padding-bottom: 7rem;
  }

  .visionTitle {
    font-size: 40px;
  }

  .teamTitle {
    font-size: 40px;
  }

  .leftBlur {
    width: 60%;
  }

  .borderRight {
    border-right: unset;
    border-bottom: 3px solid #fff;
  }

  .footerBlueBg {
    height: auto;
    padding-bottom: 5rem;
  }

  .mobileNav {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1B3282;
    font-size: 22px;
    left: 120vw;
    transition: all 0.2s ease-in-out;
  }

  .video2Container {
    height: auto;
    padding: 7rem 0rem;
  }

  .xBtn {
    position: absolute;
    top: 0.8rem;
    right: 1.5rem;
  }

  .openNav {
    left: 0px;
  }
}

@media (max-width: 600px) {
  .cookie-dialog button {
    float: right;
    font-size: 18px;
  }
}

@media (max-width: 575.98px) {
  .visionTitle {
    font-size: 35px;
  }

  .teamTitle {
    font-size: 35px;
  }

  .leftBlur {
    width: calc(100% - 1.5rem);
    height: 46px;
  }

  .orangeTitle {
    font-size: 30px;
  }

  .heroImage {
    position: absolute;
  }


}